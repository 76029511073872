import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
//import "./App.scss";
import './styles/main.scss';

//Layouts
import LPLayout from './layouts/lp';
import GameLayout from './layouts/game';

import HomeIndex from './landing-page/index';
import Watch from "./game/watch/index";
import RegisterPlayer from "./game/register-player.js";
import Admin from "./game/admin.js";

const PrivateRoute = ({ component: TheComponent, ignoreLocation, ...rest }) => {
  const isAuthenticated = true; //auth.isAuthenticated();
  /*if (!ignoreLocation) {
    auth.saveRequestedLocation(window.location.href);
  }*/
  return (
    <Route {...rest} render={(props) => (
      isAuthenticated === true
        ? <TheComponent {...props} />
        : <Redirect to='/' />
    )} />);
};

const withLayout = (LayoutComp, ComponentComp, props) => <LayoutComp><ComponentComp {...props} /></LayoutComp>;
const withGameLayout = (ComponentComp) => (props) => withLayout(GameLayout, ComponentComp, props);

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Route path="/watch/:id" component={withGameLayout(Watch)} />
          <Route path="/register/:id" component={withGameLayout(RegisterPlayer)} />
          <PrivateRoute path="/admin/:id" component={Admin} />
          <Route exact path="/" render={(props) => {
                return <LPLayout><HomeIndex {...props} /></LPLayout>;
          }} />
          {/* <Route path="/" exact={true} render={() => {
          return (<p className="App-intro">
          To get started, edit <code>src/App.js</code> and save to reload.
        </p>);
        }} /> */}
        </div>
      </Router>
    );
  }
}

export default App;
