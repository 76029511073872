import React from "react";

const Header = () => {
  return (
    <header id="header">
      <div className="container">
        <nav className="main-nav">
          {/* <a href="/">Sign up</a>
                    <a href="/login">Log in</a> */}
        </nav>
        <div className="logo">
          <img src="/images/logo.png" alt="Grab-Bagger" />
        </div>
      </div>
    </header>
  );
};

export default Header;
