import React from 'react';

import { useInput } from '../../shared/hooks/form-fields';

const GameEnter = (props) => {
    const gameIdInput = useInput('Tumminaro2019');

    const startGame = (e) => {
        props.history.push(`/watch/${gameIdInput.value}`);
    };

    return (
        <form action="#" onSubmit={startGame}>
            <div className="copy">
            <h1>Enter a Game</h1>
                <div className="input-group -no-margin">
                    <div className="form-field">
                        <input type="text" id="gameId" {...gameIdInput.bind} placeholder="Game ID" />
                    </div>
                    <button type="submit" className="button -secondary">Start</button>
                </div>
            </div>
        </form>
    );
}
 
export default GameEnter;
