import React from "react";

function getStealCountText(stealCount) {
  switch (stealCount) {
    case 2:
      return "2nd";
    case 1:
      return "1st";
    case 3:
      return "Final";
    default:
      return stealCount;
  }
}

const GameItem = props => {
  const openClass = props.gift ? " -opened" : "";
  const stealsClass =
    props.stealCount && props.stealCount >= 3 ? " -no-steals" : "";
  const itemClass = "item" + openClass + stealsClass;

  const giftName = props.gift ? props.gift : "???";
  const giftImage = props.giftImage
    ? { style: { backgroundImage: `url('${props.giftImage}')` } }
    : null;

  return (
    <div className={`${itemClass}`}>
      <div className="images">
        <div className="gift" {...giftImage}></div>
      </div>
      <div className="info-panel">
        <div className="copy">
          <strong className="gift-name">{giftName}</strong>
          <strong className="person-name">
            {props.player} {props.action === 1 ? "stole" : "opened"} it
          </strong>
        </div>

        <div className="item-nav">
          {props.stealCount > 0 && (
            <span className="steals">
              {getStealCountText(props.stealCount)}
              <br />
              Steal
            </span>
          )}
          {/*<a href="/gift/cHWwANq5iajBAJJ86" className="edit-gift"><FontAwesomeIcon icon={faGift} /></a>
                    <a href="/player/AT2S7Y8PTT5BfF2bZ"><FontAwesomeIcon icon={faUser} /></a>*/}
        </div>
      </div>
    </div>
  );
};

export default GameItem;
