import React, { Component } from 'react';

class GiftDetails extends Component {

    close = async (e) => {
        e.preventDefault();
 
        this.props.setDetailsGift(null);     

    }
    componentWillUpdate(nextProps, nextState) {
        this.updateTextBox(nextProps);
    }
    updateTextBox(props) {
        this.refs.giftDesc.value = props.gift.description;
        this.refs.comments.value = props.gift.comments || '';
        this.refs.imageUrl.value = props.gift.imageUrl || '';
    }
    render() {
        const gift = this.props.gift;
        if(!gift) {
            return <div></div>
        }

        const stolenList = gift.stolenBy;
        const steals = stolenList.length;
        const stolenS = steals === 1 ? '' : 's';
        const isGone = steals === 3;
        const ownerLabel =  isGone ? 'Final' : 'Current';
        const stolenByList = gift.stolenBy.map((p) => {
            return <li key={p._id}>then {p.nickname} stole it</li>
        });
        const image = gift.imageUrl ? <img src={gift.imageUrl} alt={gift.description} /> : '';
        return (
            <div className="details-gift ">
            <button className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect" onClick={this.close}>Back</button>
                <h3>{gift.description}</h3>
                <p>{gift.comments}</p>
                {image}
                <div>
                <ul className="details-list">
                <li>Stolen {steals} time{stolenS}</li>
                <li>{ownerLabel} Owner: {gift.owner.nickname}</li>
                <li>Opened by {gift.openedBy.nickname}</li>
                {stolenByList}
                </ul>
                </div>
                <button className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect" onClick={this.close}>Back</button>
            </div>
        );
    }
}

export default GiftDetails;
