import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faGift,
  faUserFriends
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Menu({ gameId, gameTitle }) {
  return (
    <div className="game-input">
      <ul id="add-nav" className="icon-list -large">
        <li>
          <Link to={`/watch/${gameId}`}>
            <FontAwesomeIcon icon={faList} />
          </Link>
        </li>
        <li data-placeholder="Add Gift">
          <Link to={`/watch/${gameId}/gifts`}>
            <FontAwesomeIcon icon={faGift} />
          </Link>
        </li>
        <li className="grid">
          <Link to={`/watch/${gameId}/players`}>
            <FontAwesomeIcon icon={faUserFriends} />
          </Link>
        </li>
      </ul>
      <nav className="view-nav">
        <ul id="layout-nav" className="icon-list -large">
          {/* <li className="grid">
                <FontAwesomeIcon icon={faTh} />
              </li>
              <li className="list">
                  <FontAwesomeIcon icon={faList} />
              </li> */}
        </ul>
      </nav>

      <h1 className="txt-center">{gameTitle}</h1>
      {/*<div className="main-input">
            <ButtonInput name="input" placeholder={this.getPlaceholderText()} value={this.state.input} updateField={this.updateField} buttonClick={this.addItem} />
  </div>*/}
    </div>
  );
}

export default Menu;
