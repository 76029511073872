import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChild, faCogs, faMobile } from '@fortawesome/free-solid-svg-icons'

import GameEnter from './components/game-enter';

const HomeIndex = (props) => {
    return (
        <div>
            <div className="hero -center">
                <div className="container">
                <div className="copy">
                    {/*<h1>Your Gift Exchange, Simplified.</h1>
                    <button className="button -secondary">Learn More</button>*/}
                    <GameEnter {...props} />
                </div>
                </div>
            </div>

            <div className="services txt-center">
                <div className="container">
                <h2>Services</h2>
                <div className="info-buckets">
                    <div className="bucket">
                    <div className="icon-circle"><FontAwesomeIcon icon={faChild} /></div>
                    <h3>Effortless Play</h3>
                    <p>No more keeping cutting up numbers last minute to determine turn order. Why manually track gifts and turns when Grab-Bagger can do it all for you!</p>
                    </div>
                    <div className="bucket">
                    <div className="icon-circle"><FontAwesomeIcon icon={faMobile} /></div>
                    <h3>Effortless Play</h3>
                    <p>Anyone with the shareable link can view the game on their own mobile device as well as help take pictures of the fun.</p>
                    </div>
                    <div className="bucket">
                    <div className="icon-circle"><FontAwesomeIcon icon={faCogs} /></div>
                    <h3>Effortless Play</h3>
                    <p>Configure your games just they way you like them with custom game options.</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}
 
export default HomeIndex;