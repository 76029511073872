import React, { Component } from 'react';


class CurrentTurn extends Component {
    render() {
        const player = this.props.player;
        if(!player) {
            return (<div></div>);
        }
        return (
        <div className="current-player">
        <h2>Current Turn</h2>
        <ul className=" mdl-list">
        <li key={`${player.nickname}current`} className="mdl-list__item mdl-list__item--two-line">
                <span className="mdl-list__item-primary-content">
                <i className="material-icons mdl-list__item-avatar">person</i>
                <span>{player.nickname}</span>
                <span className="mdl-list__item-sub-title">turn: {player.turn}</span>
                </span>
                <span className="mdl-list__item-secondary-content">
                
                </span>
            </li>
        </ul>
        </div>
        );
    }
}

export default CurrentTurn;
