import React, { Component } from 'react';
import {addGame} from '../../services/http';

class AddGame extends Component {
    add = (e) => {
        e.preventDefault();
        const gameName = this.refs.gameName.value;
        if (!gameName) { return; }
        addGame(this.props.gameId, gameName);
        this.refs.gameName.value = '';
        this.props.update();

    }
    render() {
        return (
            <div className="form-group">
                <label>Game Name</label>
                <input type="text" ref="gameName" />
                <button onClick={this.add}>Add Game</button>
            </div>
        );
    }
}

export default AddGame;
