import React from "react";

const GiftItem = ({ gift }) => {
  const { description, imageUrl, stolenBy, owner } = gift;
  const giftName = description;
  //   const openClass =gift ? " -opened" : "";
  //   const stealsClass =
  //     props.stealCount && props.stealCount >= 3 ? " -no-steals" : "";
  //   const itemClass = "item" + openClass + stealsClass;

  //   const giftName = gift ? gift : "???";
  const giftImage = imageUrl
    ? { style: { backgroundImage: `url('${imageUrl}')` } }
    : null;

  return (
    <div className={`item`}>
      <div className="images">
        <div className="gift" {...giftImage}></div>
      </div>
      <div className="info-panel">
        <div className="copy">
          <strong className="gift-name">{giftName}</strong>
          <strong className="person-name">{owner.nickname} has it</strong>
        </div>

        <div className="item-nav">
          <span className="steals">{stolenBy.length}</span>
        </div>
      </div>
    </div>
  );
};

export default GiftItem;
