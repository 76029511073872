import React, { Component } from 'react';
import EditGift from './admin/editGift';
import GiftDetails from './admin/giftDetails';
import "../scss/_gifts.scss";

class Gifts extends Component {
    constructor() {
        super();
        this.state = {
            editingGift: null,
            detailsGift: null
        }
    }
    setEditingGift = (e, gift) => {
        e.stopPropagation();
        this.setState({
            editingGift: gift
        });
        return false;
    }
    setDetailsGiftClick = (e, g) => {
        this.setState({
            detailsGift: g
        });
    }
    setMode = (mode) => {
        this.setState({
            detailsGift: null
        });
        this.props.setMode(mode);
    }
    renderGift = (gift, i) => {
        const stolenList = gift.stolenBy;
        const steals = stolenList.length;
        const isGone = steals === 3;
        const ownerLabel =  isGone ? 'Final' : 'Current';
        const giftContainerClasses = isGone ? 'mdl-list__item mdl-list__item--two-line gift-item gone' : 'mdl-list__item mdl-list__item--two-line gift-item';
        const image = gift.imageUrl ? <img className="img-circle" src={gift.imageUrl} alt={gift.description} /> : <i className="material-icons mdl-list__item-avatar">card_giftcard</i>;
        const editButtons = this.props.isAdmin ? <button onClick={(e) => this.setEditingGift(e, gift)}>Edit</button> : '';
        return (
            <li className={giftContainerClasses} key={`giftlist${i}`} onClick={(e) => this.setDetailsGiftClick(e, gift)}>
                <span className="mdl-list__item-primary-content">
                {image}
                <span>{gift.description}</span>
                <span className="mdl-list__item-sub-title">Steals: {steals}<br /><br />
                {ownerLabel} Owner: {gift.owner.nickname}
                </span>
                </span>
                <span className="mdl-list__item-secondary-content">
                {editButtons}
                </span>
            </li>
        )
    }
    render() {
        const gifts = this.props.gifts.map(this.renderGift);
        const editGift = this.state.editingGift ? <EditGift gift={this.state.editingGift} setEditingGift={this.setEditingGift} gameId={this.props.gameId} /> : '';
        const detailsGift = this.state.detailsGift ? 
        <GiftDetails gift={this.state.detailsGift} setDetailsGift={this.setDetailsGiftClick} /> : 
        '';
        const giftListStyle = this.state.detailsGift ? { display: "none" } : {};
        return (
            <div className="gifts">
                <h2><button onClick={() => this.setMode(1)}><i className="material-icons">&#xE853;</i></button> <button onClick={() => this.setMode(2)}><i className="material-icons">&#xE8EE;</i></button> Gifts</h2>
                <div className="gift-well">
                {editGift}
                {detailsGift}
                <ul className="mdl-list" style={giftListStyle}>
                    {gifts}
                </ul>
                </div>
            </div>
            
        );
    }
}

export default Gifts;
