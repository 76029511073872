import React, { useContext } from "react";
import WatchContext from "../watchContext";

import "./gifts.scss";
import GiftItem from "./gift-item";

function GiftList() {
  const { gifts } = useContext(WatchContext);
  return (
    <div className="giftlist">
      <div className="blocks -list">
        {gifts && gifts.map(g => <GiftItem gift={g} key={g._id} />)}
      </div>
    </div>
  );
}

export default GiftList;
