import React, { Component } from 'react';
import {stealGift} from '../../services/http';

class StealGift extends Component {
    steal = (e) => {
        e.preventDefault();
        const giftId = this.refs.giftDesc.value;
        if (!giftId) { return; }

        stealGift(this.props.gameId, giftId, this.props.player._id);
        this.refs.giftDesc.value = '';
        

    }
    render() {
        const player = this.props.player;
        const availableGifts = this.props.gifts.reduce((list, g) => {
            if(g.stolenBy.length < 3) {
                list.push(g);
            }
            return list;
        }, []);
        const giftOptions = availableGifts.map(g => {
            return <option key={`steal${g._id}`} value={g._id}>{g.description}</option>
        })
        if(!player) {
            return <div></div>
        }
        return (
            <div className="form-group">
                <select ref="giftDesc">
                    <option value=''>--- Steal ---</option>
                    {giftOptions}
                </select>
                <button onClick={this.steal}>Steal Gift</button>
            </div>
        );
    }
}

export default StealGift;
