import React, { Component } from 'react';
import Noty from 'noty';
import { registerPlayer } from './services/http';

class RegisterPlayer extends Component {
    constructor(props) {
        super();
        this.gameId = props.gameId || props.match.params.id;
    }
    register = (e) => {
        e.preventDefault();
        registerPlayer({
            nickname: this.refs.playerNickName.value
        }, this.gameId).then(() => {
            new Noty({
                type: 'success',
                layout: 'top',
                text     : `${this.refs.playerNickName.value} is in!`,
                theme: 'semanticui',
                timeout: 1000,
                container: ".mdl-card__supporting-text",

            }).show();
            this.refs.playerNickName.value = '';
        });
    }
    render() {
        return (
            <div className="container txt-center">
            <form action="#" onSubmit={this.register}>
                <h4>After you put a gift under the tree, add your name to the game:</h4>
                <br />
                <div className="input-group -no-margin" style={{margin: '0 auto', width: '500px'}}>
                    <div className="form-field">
                        <input type="text" id="playerNickName" ref="playerNickName" placeholder="Player Name" />
                    </div>
                
                    <button type="submit" className="button -primary -small">Add</button>

                </div>
                <div className="mdl-card__supporting-text"></div>
                <br /><br />
            </form>
            </div>
        );
    }
}

export default RegisterPlayer;
