import React, { Component } from 'react';


class Turns extends Component {
    constructor(props) {
        super();
    }
    renderTurn = (turn, i) => {
        const totalTurns = this.props.turns.length;
        return (
            <li className="mdl-list__item" key={`turn${i}`}>
            <span className="mdl-list__item-primary-content">
            {totalTurns - i}: {turn.description}
            </span>
        </li>
        )
    }
    render() {
        var turns = this.props.turns.map(this.renderTurn);
        return (
        <div className="turns">
        <h2><button onClick={() => this.props.setMode(0)}><i className="material-icons">&#xE8F6;</i></button> <button onClick={() => this.props.setMode(1)}><i className="material-icons">&#xE853;</i></button> Turns</h2>
        <ul className="mdl-list">
            {turns}
        </ul>
        </div>
        );
    }
}

export default Turns;
