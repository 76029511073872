import React, { Component } from 'react';
import CurrentTurn from './currentTurn';

class Players extends Component {
    renderPlayer = (player, i) => {
        return (
            <li key={`${player.nickname}${i}`} className="mdl-list__item mdl-list__item--two-line">
                <span className="mdl-list__item-primary-content">
                <i className="material-icons mdl-list__item-avatar">person</i>
                <span>{player.nickname}</span>
                <span className="mdl-list__item-sub-title">turn: {player.turn}</span>
                </span>
                <span className="mdl-list__item-secondary-content">
                
                </span>
            </li>
            
        )
    }
    render() {
        var players = this.props.players.map(this.renderPlayer);
        return (
        <div className="players">
        <CurrentTurn player={this.props.player} />
        <h2><button onClick={() => this.props.setMode(0)}><i className="material-icons">&#xE8F6;</i></button> <button onClick={() => this.props.setMode(2)}><i className="material-icons">&#xE8EE;</i></button> Players</h2>
        <div className="players-container">
        <ul className=" mdl-list">
            {players}
        </ul>
        </div>
        </div>
        );
    }
}

export default Players;
