import React, { useContext } from "react";
import WatchContext from "../watchContext";
import "./playerlist.scss";

const PlayerList = () => {
  const { players } = useContext(WatchContext);
  return (
    <div className="playerlist">
      <ul>
        {players.map(p => (
          <li key={`playerslist_${p._id}`}>
            {p.turn}: {p.nickname}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlayerList;
