import Nes from "nes";

const host =
  process.env.NODE_ENV !== "production"
    ? "ws://localhost:8000"
    : "wss://api.grabbagger.shipb.us";
export function watchPlayers(handler, gameId) {
  const client = new Nes.Client(host);
  const start = async () => {
    await client.connect();

    client.subscribe(`/api/players/${gameId}`, handler);
  };
  start();
}

export function watchTurns(handler, gameId) {
  const client = new Nes.Client(host);
  const start = async () => {
    await client.connect();

    client.subscribe(`/api/turns/${gameId}`, handler);
  };
  start();
}

export function watchGifts(handler, gameId) {
  const client = new Nes.Client(host);
  const start = async () => {
    await client.connect();

    client.subscribe(`/api/gifts/${gameId}`, handler);
  };
  start();
}
