import React, { Component } from "react";
import { updateGift, uploadImage } from "../../services/http";
import { Uploader } from "./uploader";

class EditGift extends Component {
  // compnentDidMount() {
  //     this.updateTextBox(this.props);
  // }
  update = async e => {
    e.preventDefault();
    const giftDescription = this.refs.giftDesc.value;
    const comments = this.refs.comments.value;
    const imageUrl = this.refs.imageUrl.value;
    if (!giftDescription) {
      return;
    }

    await updateGift(this.props.gameId, {
      giftId: this.props.gift._id,
      description: giftDescription,
      comments,
      imageUrl
    });
    this.props.setEditingGift(e, null);
  };
  componentWillUpdate(nextProps, nextState) {
    this.updateTextBox(nextProps);
  }
  updateTextBox(props) {
    this.refs.giftDesc.value = props.gift.description;
    this.refs.comments.value = props.gift.comments || "";
    this.refs.imageUrl.value = props.gift.imageUrl || "";
  }
  uploadHandler = async e => {
    return uploadImage(
      this.props.gameId,
      this.props.gift._id,
      e.target.files[0]
    ).then(() => {
      this.props.setEditingGift(e, null);
    });
  };
  render() {
    const gift = this.props.gift;
    if (!gift) {
      return <div />;
    }

    return (
      <div className="edit-gift form-group">
        <div className="">
          <label className="" htmlFor="giftDescription">
            Description
          </label>
          <input
            className="mdl-textfield__input"
            id="giftDescription"
            ref="giftDesc"
            defaultValue={gift.description}
            type="text"
          />
        </div>
        <div className="">
          <label className="" htmlFor="comments">
            Comments
          </label>
          <input
            className="mdl-textfield__input"
            id="comments"
            ref="comments"
            defaultValue={gift.comments}
            type="text"
          />
        </div>
        <div className="">
          <label className="" htmlFor="imageUrl">
            Image Url
          </label>
          <input
            className="mdl-textfield__input"
            id="imageUrl"
            ref="imageUrl"
            defaultValue={gift.imageUrl}
            type="text"
          />
          <Uploader id="giftUpload" upload={this.uploadHandler} />
        </div>
        <button
          className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
          onClick={this.update}
        >
          Update Gift
        </button>
      </div>
    );
  }
}

export default EditGift;
