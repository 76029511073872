import React, { useContext } from "react";

import GameItem from '../../components/game-item';
import { ReactComponent as UnopenedGift } from "../unopened-gift.svg";
import WatchContext from "../watchContext";

import "./playlist.scss";

/*<div className="gift-card next-up">
          <h1>Next Up: {`${nextUp.nickname}`}</h1>
      </div>

      
    <div
              className={`gift-card ${action === 0 ? "open" : "steal"}`}
              key={`turncard_${id}`}
            >
              <h1>{`${player} ${action === 0 ? "Opened" : "Stole"}`} </h1>
              {giftImage ? (
                <img alt={`${gift}`} className="gift-image" src={giftImage} />
              ) : (
                <UnopenedGift className="gift-image" />
              )}
              <h2>
                {gift} {action === 1 && <i>{stealCount}</i>}
              </h2>
            </div>
      */

function PlayList() {
  const { turns, currentTurn, nextUp } = useContext(WatchContext);

  return (
    <div className="playlist">
      {nextUp && <div className="gift-card next-up"><h4>Next Up: {`${nextUp.nickname}`}</h4></div>}

      {currentTurn && (
        <div className="gift-card current">
          <h3>Current Turn: {`${currentTurn.nickname}`}</h3>
          <UnopenedGift className="gift-image" />
        </div>
      )}
      <div id="game-list" className="blocks -list">
        {turns &&
          turns.map(t => {
            const { id } = t;
            return (
              <GameItem key={id} {...t} />
            );
          })}
      </div>
    </div>
  );
}

export default PlayList;
