import { useEffect, useState } from "react";
import { getPlayers, getTurnInfo, getGame, getGifts } from "../services/http";
import { watchTurns, watchGifts, watchPlayers } from "../services/websockets";

const useWatch = gameId => {
  const [game, setGame] = useState({});
  const [players, setPlayers] = useState([]);
  const [gifts, setGifts] = useState([]);
  const [turns, setTurns] = useState([]);
  const [currentTurnNumber, setCurrentTurnNumber] = useState(1);
  const [currentTurn, setCurrentTurn] = useState({});
  const [nextUp, setNextUp] = useState();
  useEffect(() => {
    getPlayers(gameId).then(p => {
      setPlayers(p);
    });
    getGame(gameId).then(g => {
      setGame(g);
    });
    getGifts(gameId).then(g => {
      setGifts(g);
    });
    getTurnInfo(gameId).then(tInfo => {
      setTurns(tInfo.playlist);
      setCurrentTurn(tInfo.current);
      setCurrentTurnNumber(tInfo.currentTurnNumber);
      setNextUp(tInfo.nextUp);
    });
    watchTurns(t => {
      setCurrentTurn(t.current);
      setTurns(t.playlist);
      setCurrentTurnNumber(t.currentTurnNumber);
      setNextUp(t.nextUp);
    }, gameId);
    watchGifts(g => {
      setGifts(g);
    }, gameId);
    watchPlayers(p => {
      setPlayers(p);
    }, gameId);
  }, [gameId]);

  return {
    players,
    game,
    turns,
    currentTurnNumber,
    currentTurn,
    nextUp,
    gifts
  };
};

export default useWatch;
