import React from "react";

export class Uploader extends React.Component {
  constructor() {
    super();
    this.state = {
      uploading: false
    };
  }
  uploadHandler = e => {
    this.setState({
      uploading: true
    });
    this.props.upload(e).then(() => {
      window.setTimeout(() => {
        this.setState({
          uploading: false
        });
      }, 700);
    });
  };
  onClick = e => {
    e.preventDefault();
    document.getElementById(this.props.id).click();
  };
  render() {
    const hasFile = this.props.hasFile ? (
      <i className="fa fa-2x fa-check valid" aria-hidden="true" />
    ) : (
      "No File Selected"
    );
    const button = this.state.uploading ? (
      <i className="fa fa-cog fa-spin fa-3x fa-fw" />
    ) : (
      <button className="btn btn-upload" onClick={this.onClick}>
        Select File
      </button>
    );
    return (
      <div>
        {button} {hasFile}
        <input
          type="file"
          accept="image/*;capture=camera"
          onChange={this.uploadHandler}
          className="hidden form-control-file"
          id={this.props.id}
          aria-describedby={`${this.props.id}Help`}
        />
      </div>
    );
  }
}
