import React, { Component } from 'react';
import {openGift} from '../../services/http';

class OpenGift extends Component {
    open = (e) => {
        e.preventDefault();
        const giftDescription = this.refs.giftDesc.value;
        if (!giftDescription) { return; }

        openGift(this.props.gameId, giftDescription, this.props.player._id);
        this.refs.giftDesc.value = '';
        

    }
    render() {
        const player = this.props.player;
        if(!player) {
            return <div></div>
        }
        return (
            <div className="form-group">
                <input type="text" ref="giftDesc" />
                <button onClick={this.open}>Add Gift</button>
            </div>
        );
    }
}

export default OpenGift;
