import React, { Component } from "react";
import "./game.scss";
import Players from "./components/players";
import Turns from "./components/turns";
import Gifts from "./components/gifts";
import OpenGift from "./components/admin/openGift";
import StealGift from "./components/admin/stealGift";
import AddGame from "./components/admin/addGame";
import {
  getPlayers,
  shufflePlayers,
  getTurnInfo,
  getGame,
  startGame,
  getGifts,
  undoTurn
} from "./services/http";
import { watchPlayers, watchTurns, watchGifts } from "./services/websockets";
import RegisterPlayer from "./register-player";

class Admin extends Component {
  constructor(props) {
    super();
    this.gameName = props.match.params.id;
    this.gameId = props.match.params.id;
    this.state = {
      players: [],
      turns: [],
      gifts: [],
      current: {},
      game: false
    };
  }
  componentDidMount() {
    this.updateGame();
    this.updateTurns();
    getPlayers(this.gameId).then(players => {
      this.setState({ players });
    });
    getGifts(this.gameId).then(gifts => {
      this.setState({ gifts: gifts });
    });
    watchPlayers(this.playerWatchHandler, this.gameId);
    watchTurns(this.turnsWatchHandler, this.gameId);
    watchGifts(this.giftWatchHandler, this.gameId);
  }
  playerWatchHandler = (update, flags) => {
    this.setState({ players: update });
  };
  turnsWatchHandler = (update, flags) => {
    this.setState({ turns: update.turns, current: update.current });
  };
  giftWatchHandler = (update, flags) => {
    this.setState({ gifts: update });
  };
  shufflePlayersClick = () => {
    shufflePlayers(this.gameId);
  };
  startGameClick = () => {
    startGame(this.gameId).then(() => {
      this.updateGame();
    });
  };
  updateGame = () => {
    getGame(this.gameId).then(game => {
      this.setState({ game });
    });
  };
  updateTurns = () => {
    getTurnInfo(this.gameId).then(turns => {
      this.setState({ turns: turns.turns || [], current: turns.current });
    });
  };
  undoTurnClick = e => {
    undoTurn(this.gameId);
  };
  setMode = mode => {
    this.setState({
      mode
    });
  };
  renderGameStarted() {
    return (
      <div>
        <div className="content">
          <Players
            players={this.state.players}
            player={this.state.current}
            setMode={this.setMode}
          />
          <Turns turns={this.state.turns} setMode={this.setMode} />
          <Gifts
            gifts={this.state.gifts}
            isAdmin={true}
            gameId={this.gameId}
            setMode={this.setMode}
          />
        </div>
        <OpenGift gameId={this.gameId} player={this.state.current} />
        <StealGift
          gameId={this.gameId}
          player={this.state.current}
          gifts={this.state.gifts}
        />
        <button onClick={this.undoTurnClick}>Undo Turn</button>
      </div>
    );
  }
  renderGamePreStart() {
    return (
      <div>
        <Players players={this.state.players} />
        <RegisterPlayer gameId={this.gameId} />
        <button onClick={this.shufflePlayersClick}>Shuffle</button>
        <button onClick={this.startGameClick}>Start Game</button>
      </div>
    );
  }
  render() {
    if (!this.state.game) {
      return (
        <div>
          <AddGame gameId={this.gameId} update={this.updateGame} />
        </div>
      );
    }

    const game = this.state.game.isStarted
      ? this.renderGameStarted()
      : this.renderGamePreStart();
    return (
      <div className="enter watch">
        <h1>Admin for {this.state.game.name}</h1>
        {game}
      </div>
    );
  }
}

export default Admin;
