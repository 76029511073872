import React from 'react';

import Header from '../landing-page/components/header';

const LPLayout = (props) => {
  return (
    <div id="lp">
      <Header />

      <main id="main">
        {props.children}
      </main>

      <footer id="footer">
        <div className="container">
          &copy; <script>document.write(new Date().getFullYear());</script> Grab-Bagger
        </div>
      </footer>
    </div>
  );
};

export default LPLayout;
