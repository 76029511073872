import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import useWatch from "./useWatch";
import WatchContext from "./watchContext";
import PlayList from "./playlist";
import GiftList from "./giftlist";
import Menu from "./menu";
import PlayerList from "./playerlist";

const Watch = ({ match }) => {
  const gameId = match.params.id;
  const gameWatch = useWatch(gameId);
  return (
    <div id="game" className="watch-cards">
      <WatchContext.Provider value={{ ...gameWatch }}>
        <Router>
          <div className="container">
            <Menu gameId={gameId} gameTitle={gameWatch.game.name} />
            <Route exact={true} path="/watch/:id/" component={PlayList} />
            <Route path="/watch/:id/gifts" component={GiftList} />
            <Route path="/watch/:id/players" component={PlayerList} />
          </div>
        </Router>
      </WatchContext.Provider>
    </div>
  );
};

export default Watch;
